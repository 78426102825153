import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent{

  titulado:FormGroup = this._builder.group({
    rut: ['', [Validators.required]]
  })

  class_mensaje_rut: string = 'text-success';
  mensajeRut      : string = '';
  rutValido       : boolean = true;
  mensajeCorrecto : string = '';

  constructor( private router:Router,
              private authService: AuthService,
              private _builder: FormBuilder ) { }

  login(){
    var rut_completo = this.titulado.controls['rut'].value
    const newRut = rut_completo.replace(/\./g,'').replace(/\-/g, '').trim();

    const dv = newRut.substring(newRut.length-1, newRut.length);
    const rut = newRut.substring(0, newRut.length-1)

    this.authService.login(rut)
    .subscribe( resp => {
      if ( resp.rut ) {
        this.router.navigate(['./ofertas']);
      }else{
        this.router.navigate(['./error']);
      }

    })
  }

  checkRut():boolean{
    var rut = this.titulado.controls['rut'].value;

    const newRut = rut.replace(/\./g,'').replace(/\-/g, '').trim();

    const lastDigit = newRut.substring(newRut.length-1, newRut.length);
    const rutDigit = newRut.substring(0, newRut.length-1)

    let format = '';

    for (let i = rutDigit.length; i > 0; i--) {
      const e = rutDigit.charAt(i-1);
      format = e.concat(format);
      if (i % 3 === 0){
        format = '.'.concat(format);
      }
    }
    this.titulado.controls['rut'].setValue(format.concat('-').concat(lastDigit));

    // Despejar Puntos
    var valor = rut.replace('.','').replace('.','').replace('.','');
    // Despejar Guión
    valor = valor.replace('-','');

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0,-1);
    var dv:string = valor.slice(-1).toUpperCase();

    // Formatear RUN
    rut = cuerpo + '-'+ dv;

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) {
      this.class_mensaje_rut = 'text-danger'
      this.mensajeRut = 'RUT incompleto';
      this.rutValido  = true;
      return false;
    }

    // Calcular Dígito Verificador
    var  suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for(var i=1;i<=cuerpo.length;i++) {

        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * parseInt( valor.charAt( cuerpo.length - i) );

        // Sumar al Contador General
        suma = suma + index;

        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv == 'K'?"10":dv;
    dv = dv == "0"?"11":dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado+'' != dv) {
      this.class_mensaje_rut = 'text-danger'
      this.mensajeRut = "RUT inválido";
      this.rutValido  = true;
    return false;
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
    this.class_mensaje_rut = 'text-success'
    this.mensajeRut = "RUT correcto";
    this.rutValido  = false;
    return true;
  }

}
