<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <img class="img-fluid" src="../assets/images/logo2.png" width="230">
    </div>
    <div class="col-5"></div>
    <div class="col-3">
      <img class="img-fluid" src="../assets/images/somosacademia.gif" width="230">
    </div>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-10">
    <div class="card">
      <div class="card-header">
        <h1 class="text-center text-secondary">Bolsa de Trabajo</h1>
      </div>
      <div class="p-3 mb-4">

        <form class="row" [formGroup]="titulado" (ngSubmit)="login()">
          <div class="col-md-10">
            <label  for="rut" class="form-label">RUT</label>
            <input class="form-control" id="rut" type="username" formControlName="rut" (keyup)="checkRut()"onkeypress="if (((event.keyCode < 48) || (event.keyCode > 57)) && event.keyCode != 107 && event.keyCode != 75) return false;" aria-describedby="rut-help" pInputText  />
          </div>

          <div class="col-md-2">
            <label for="validationCustom02" class="form-label">&nbsp;</label>
            <button class="btn btn-primary form-control" [disabled]="rutValido" type="Submit"  >Ingresar</button>
          </div>

          <br/>
          <div class="col-12 text-center mt-2">
            <small id="rut-help" class="block {{ class_mensaje_rut}}">{{ mensajeRut }}</small>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>





