import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor() { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    var header = req.headers;
    const token = environment.token;

    header = header.set('x-api-key', token);

    const reqClone = req.clone({
      headers:header

    });

    return next.handle( reqClone ).pipe(
      catchError ( err => {
        return this.mensajeError(err)
      } )
    )
  }

  mensajeError(error: HttpErrorResponse){
    console.log('Error de interceptor');
    console.warn(error);
    //return throwError('Interceptor: Error no abordado')
    return throwError(() => new Error('Interceptor: Error no abordado'));

  }

}
