<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <img class="" src="../assets/images/logo2.png" width="230">
    </div>
    <div class="col-5"></div>
    <div class="col-3">
      <img class="" src="../assets/images/somosacademia.gif" width="230">
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-2"></div>
  <div class="col">
    <div class="card">
      <div class="p-3 mb-4">
        <div class="align-items-center border-bottom-1 surface-border surface-overlay w-full">
          <p class="text-3xl w-12 text-center mr-3">
            Según nuestros registros, no figuras
            como titulada/o de nuestra institución.
          </p>
          <p class="text-center text-2xl w-12"> Si deseas solucionar esta situación,
            o enviarnos una oferta laboral,
            escribe al correo titulados@academia.cl
          </p>
        </div>
        <div class="flex justify-content-end mt-2">
          <button (click)="volver()" class="btn btn-primary" type="button">Volver</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>





