import { NgModule } from '@angular/core';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [],
  exports:[
    AvatarModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    ProgressBarModule,
    SelectButtonModule
  ]
})
export class PrimengModule { }
