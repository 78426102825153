<p-card header="Filtrar por" [style]="{'height': '100%', 'padding':'1em'}">
  <div *ngFor="let categoria of lista_categorias">
    <div class="texto-medio mb-2">{{ categoria.nombre | titlecase }}</div>
    <div *ngFor="let filtro of lista_filtros; let i = index">
      <div *ngIf="filtro.categoria == categoria.id " class="mb-2 ml-2">
        <p-inputSwitch (onChange)="tocaInput(filtro.id)" class="mr-1"></p-inputSwitch>
        <label>{{ filtro.nombre | titlecase }}</label>
      </div>
    </div>
  </div>
</p-card>
