import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ComunicacionService } from '../../services/comunicacion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {

  open_menu: boolean = false;
  viendoDetalle!: boolean;


  get auth(){
    //Es el servicio el que tiene la informacion del usuario logueado
    return this.authService.auth;
  }

  constructor(private authService: AuthService,
              private comunicacionService: ComunicacionService ) { }

  ngOnInit(): void {
    this.comunicacionService.viendoDetalleObservable.subscribe({
      next:(viendo) => {
        this.viendoDetalle = viendo;
      }
    })
  }


}
