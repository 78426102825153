<!--
<div class="flex justify-content-between flex-wrap header header">
  <div class="flex justify-content-start align-items-center">
    <img class="" src="../assets/images/logo_uahc_h_s_white.png" width="150">
  </div>
  <div class="flex justify-content-end align-items-center">
    <div class="">
      <div class="flex justify-content-end flex-wrap container ">

        <div class="m-2 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <i class="pi pi-bars" style="color: #FFFFFF; font-size: 1.8rem"></i>
        </div>

        <div class="m-2 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div *ngIf="auth.nombre_social !== null" class="nombre_usuario">
            {{ auth.nombre_social | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}}
          </div>
          <div *ngIf="auth.nombre_social === null" class="nombre_usuario">
            {{ auth.nombres | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}}
          </div>
        </div>
        <div class="m-2">
          <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#F44748', 'color': '#ffffff'}" shape="circle"></p-avatar>
        </div>
      </div>
  </div>
  </div>
</div>
<div *ngIf="open_menu">
  <app-sidebar></app-sidebar>
</div> -->


<nav class="navbar navbar-dark bg-dark">
  <div class="container-fluid">
    <img class="" src="../assets/images/logo_uahc_h_s_white.png" width="150">
    <div class="m-2 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
      <div *ngIf="auth.nombre_social !== null" class="nombre_usuario">
        {{ auth.nombre_social | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}} &nbsp;
        <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#F44748', 'color': '#ffffff'}" shape="circle"></p-avatar>
      </div>
      <div *ngIf="auth.nombre_social === null" class="nombre_usuario">
        {{ auth.nombres | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}} &nbsp;
        <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#F44748', 'color': '#ffffff'}" shape="circle"></p-avatar>
      </div>
    </div>
    <button class="navbar-toggler d-block d-md-none d-lg-none d-xl-none d-xxl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
          <div class="m-2">
            <div *ngIf="auth.nombre_social !== null" class="nombre_usuario">
              <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#F44748', 'color': '#ffffff'}" shape="circle"></p-avatar>
              {{ auth.nombre_social | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}}
            </div>
            <div *ngIf="auth.nombre_social === null" class="nombre_usuario">
              <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#F44748', 'color': '#ffffff'}" shape="circle"></p-avatar>
              {{ auth.nombres | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}}
            </div>
          </div>
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body" *ngIf="!viendoDetalle">
        <app-sidebar></app-sidebar>
      </div>
    </div>
  </div>
</nav>
